import { css } from 'styled-components'
import { sizes } from './sizes'

export const spacing = {
  size01: '4px',
  size02: '8px',
  size03: '12px',
  size04: '16px',
  size05: '24px',
  size06: '32px',
  size07: '48px',
  size08: '64px',
  size09: '96px',
  size10: '128px',
  size11: '192px',
  size12: '256px',
  size13: '384px',
  size14: '512px',
  size15: '640px',
  size16: '768px',
}

export const fontSize = {
  mobileText: '16px',
  mobileHeading: '24px',
  mobilesubheading: '16px',
  mobileBigHeading: '28px',
  mobileFooterHeading: '16px',
  mobileFooterText: '18px',
  mobileCopyright: '12px',
  mobileIconLinkHeading: '16px',
  tabletText: '18px',
  tabletHeading: '36px',
  tabletBigHeading: '42px',
  tabletLabelInput: '14px',
  label: '14px',
  text: '21px',
  footerHeading: '20px',
  heading: '36px',
  subheading: '24px',
  menuitem: '21px',
  contactbutton: '18px',
  labelinput: '14px',
  contactinput: '14px',
  teammembername: '24px',
  teammemberabout: '18px',
  copyright: '12px',
  iconLinkHeading: '24px',
  bigHeading: '48px',
}

export const colors = {
  light: '#FFFFFF',
  dark: '#444444',
  error: '#EB5557',
  background: '#F2F2F2',
  black: 'black',
  grey: '#ECECEC',
}

export const fontStyle = {
  montserrat: '"Montserrat", sans-serif',
  openSans: '"Open Sans", sans-serif',
  openSansBold: '"Open Sans Bold", sans-serif',
}

const subheading = css`
  font-family: ${fontStyle.montserrat};
  font-size: ${fontSize.subheading};
  line-height: 48px;
  font-weight: 800;
  @media (max-width: ${sizes.breakpointsMax.sm}) {
    font-size: ${fontSize.mobilesubheading};
    line-height: 24px;
  }
`
const heading = css`
  line-height: 48px;
  font-family: ${fontStyle.montserrat};
  font-size: ${fontSize.heading};
  font-weight: 800;
  @media (max-width: ${sizes.breakpointsMax.lg}) {
    font-size: ${fontSize.tabletHeading};
  }
  @media (max-width: ${sizes.breakpointsMax.sm}) {
    font-size: ${fontSize.mobileHeading};
    line-height: 28px;
  }
`

const text = css`
  line-height: 32px;
  font-family: ${fontStyle.openSans};
  font-size: ${fontSize.text};
  @media (max-width: ${sizes.breakpointsMax.lg}) {
    font-size: ${fontSize.tabletText};
    line-height: 30px;
  }
  @media (max-width: ${sizes.breakpointsMax.sm}) {
    font-size: ${fontSize.mobileText};
    line-height: 28px;
  }
`

const textBold = css`
  font-family: ${fontStyle.openSansBold};
  font-size: ${fontSize.text};
  line-height: 32px;
  font-weight: bold;
  @media (max-width: ${sizes.breakpointsMax.lg}) {
    font-size: ${fontSize.tabletText};
    line-height: 30px;
  }
  @media (max-width: ${sizes.breakpointsMax.sm}) {
    font-size: ${fontSize.mobileText};
    line-height: 28px;
  }
`

const textList = css`
  line-height: 32px;
  font-family: ${fontStyle.openSans};
  font-size: ${fontSize.text};
  @media (max-width: ${sizes.breakpointsMax.lg}) {
    font-size: ${fontSize.tabletText};
    line-height: 30px;
  }
  @media (max-width: ${sizes.breakpointsMax.sm}) {
    font-size: ${fontSize.mobileText};
    line-height: 28px;
  }
`

const footerHeading = css`
  font-family: ${fontStyle.montserrat};
  font-size: ${fontSize.footerHeading};
  line-height: 24px;
  font-weight: bold;
  @media (max-width: ${sizes.breakpointsMax.sm}) {
    font-size: ${fontSize.mobileFooterHeading};
    line-height: 19px;
  }
`

const footerSubHeading = css`
  font-family: ${fontStyle.montserrat};
  line-height: 22px;
  @media (max-width: ${sizes.breakpointsMax.sm}) {
    font-size: ${fontSize.mobileFooterText};
  }
`

const menuitem = css`
  font-family: ${fontStyle.montserrat};
  font-size: ${fontSize.menuitem};
  line-height: 29px;
  font-weight: 300;
`

const labelinput = css`
  font-family: ${fontStyle.montserrat};
  font-size: ${fontSize.labelinput};
  line-height: 48px;
  font-weight: bold;
  @media (max-width: ${sizes.breakpointsMax.lg}) {
    font-size: ${fontSize.tabletLabelInput};
  }
`
const contactbutton = css`
  font-family: ${fontStyle.montserrat};
  font-size: ${fontSize.contactbutton};
  line-height: 48px;
  font-weight: bold;
`
const contactinput = css`
  font-family: ${fontStyle.openSans};
  font-size: ${fontSize.contactinput};
  line-height: 48px;
`

const teammembername = css`
  font-family: ${fontStyle.montserrat};
  font-size: ${fontSize.teammembername};
  line-height: 48px;
  font-weight: 800;
`
const teammemberabout = css`
  font-family: ${fontStyle.openSans};
  font-size: ${fontSize.teammemberabout};
  font-style: italic;
  line-height: 30px;
`
const copyright = css`
  font-family: ${fontStyle.montserrat};
  font-size: ${fontSize.copyright};
  line-height: 15px;
  @media (max-width: ${sizes.breakpointsMax.sm}) {
    font-size: ${fontSize.mobileCopyright};
  }
`
const iconLinkHeading = css`
  font-family: ${fontStyle.montserrat};
  font-size: ${fontSize.iconLinkHeading};
  line-height: 52px;
  font-weight: 800;
  @media (max-width: ${sizes.breakpointsMax.sm}) {
    font-size: ${fontSize.mobileIconLinkHeading};
  }
`
const bigHeading = css`
  font-family: ${fontStyle.montserrat};
  font-size: ${fontSize.bigHeading};
  line-height: 56px;
  font-weight: 800;
  @media (max-width: ${sizes.breakpointsMax.lg}) {
    font-size: ${fontSize.tabletBigHeading};
    line-height: 52px;
  }
  @media (max-width: ${sizes.breakpointsMax.sm}) {
    font-size: ${fontSize.mobileBigHeading};
    line-height: 38px;
  }
`
const contact = css`
  font-family: ${fontStyle.openSans};
  font-size: ${fontSize.teammemberabout};
  line-height: 30px;
`
export const fontStyles = {
  heading,
  subheading,
  footerSubHeading,
  footerHeading,
  text,
  textBold,
  menuitem,
  textList,
  contactbutton,
  contactinput,
  labelinput,
  teammembername,
  teammemberabout,
  copyright,
  iconLinkHeading,
  bigHeading,
  contact,
}
