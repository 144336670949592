export const GCMS_FACEBOOK_EN = 'https://www.facebook.com/LabrulezGCMS-EN-117225460123712/'
export const GCMS_FACEBOOK_CS = 'https://www.facebook.com/LabRulezGCMS'

export const LCMS_FACEBOOK_EN = 'https://www.facebook.com/labrulezlcmsEN/'
export const LCMS_FACEBOOK_CS = 'https://www.facebook.com/Labrulezlcms-102350331157918/'

export const GCMS_TWITTER_EN = 'https://twitter.com/labrulezgcms'
export const GCMS_TWITTER_CS = 'https://twitter.com/GcmsLab'

export const LCMS_TWITTER_EN = 'https://twitter.com/labrulezlcms'
export const LCMS_TWITTER_CS = 'https://twitter.com/LabLcms'

export const GCMS_LINKEDIN_EN = 'https://www.linkedin.com/company/labrulezlcms-en'
export const GCMS_LINKEDIN_CS = 'https://www.linkedin.com/company/28516149'

export const LCMS_LINKEDIN_EN = 'https://www.linkedin.com/company/labrulezlcms-en'
export const LCMS_LINKEDIN_CS = 'https://www.linkedin.com/company/labrulezlcms'
