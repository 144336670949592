import React from 'react'
import { useEffect } from 'react'
import { initGA, logPageView } from '../utils/analytics'
import { injectIntl, IntlFormatters } from 'gatsby-plugin-intl'

type LocaleKey = 'en' | 'cs'

const GoogleAnalyticsUninjected = ({ intl }: IntlFormatters) => {
  useEffect(() => {
    const gaKeys: Record<LocaleKey, string | undefined> = {
      en: process.env['GATSBY_GA_TAG_LANDING_PAGE_EN'],
      cs: process.env['GATSBY_GA_TAG_LANDING_PAGE_CS'],
    }
    const gaKey = gaKeys[intl.locale as LocaleKey]
    if (!gaKey) {
      console.error(`Google Analytics key for locale \`${intl.locale}\` not found`)
    }
    initGA(gaKey || '')
    logPageView()
  }, [])

  return <></>
}

export const GoogleAnalytics = injectIntl(GoogleAnalyticsUninjected)
