import { Link } from 'gatsby'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Logo from '../images/Labrulez_whole_white.svg'
import Menu from '../images/outline-menu-24px.svg'
import CzFlag from '../images/CZ_flag.svg'
import EnFlag from '../images/EN_flag.svg'
import Icpms from '../images/LabRulezICPMS_logo_white.svg'
import Lcms from '../images/LabRulezLCMS_logo_white.svg'
import Gcms from '../images/LabRulezGCMS_logo_white.svg'
import Close from '../images/close.svg'
import { colors } from '../styles/variables'
import { fontStyles } from '../styles/variables'
import { injectIntl, IntlFormatters } from 'gatsby-plugin-intl'
import Info from './footer/components/info'
import SocialMedia from './footer/components/social-media'
import { sizes } from '../styles/sizes'

const Wrapper = styled.header<{ $isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  z-index: 1000;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  padding-top: 10px;
  background-color: ${colors.black};

  padding-bottom: 8px;
  background: linear-gradient(to right, #ffc107 0%, #f27405 22%, #d92b04 42%, #be0f8f 66%, #2975d9 88%, #2fc3d4 100%)
    left bottom ${colors.black} no-repeat;
  background-size: 100% 8px;

  z-index: 60;

  @media (max-width: 1051px) {
    box-shadow: ${(p) => (p.$isOpen ? '0px 3px 32px rgba(0, 0, 0, 0.16)' : 'unset')};

    align-items: baseline;
  }

  /* Logo */
  & > .logo-wrapper {
    top: 24px;
    left: 32px;
    width: 179px;
    height: 32px;
    padding-left: 20px;
  }

  /* Logo Menu */
  & .burger-menu {
    cursor: pointer;
    padding-right: 20px;
    transition: opacity 0.5s ease-out;
  }
  & .burger-menu:active {
    opacity: 0.4;
  }

  /* Menu */
  & .menu {
    background-color: black;
    position: absolute;
    top: 0px;
    right: -150px;
    height: 100vh;
    width: 370px;
    max-width: 370px;
    transform: translateX(${(props) => (props.$isOpen ? '-150px' : '450px')});
    transition: transform 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    overflow-y: scroll;
    @media (max-width: ${sizes.breakpointsMax.sm}) {
      width: 100%;
      max-width: 100%;
    }
    .bottom {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      padding-top: 60px;
      padding-bottom: 70px;
    }
  }
  & .menu-top-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background: black;
    border-width: 0px 0px 8px 0px;
    border-style: solid;
    border-image: linear-gradient(
        to right,
        rgb(255, 193, 7),
        rgb(242, 116, 5),
        rgb(217, 43, 4),
        rgb(122, 9, 92),
        rgb(41, 117, 217),
        rgb(47, 195, 212)
      )
      1 / 1 / 0 stretch;
  }
  & .menu-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 80px;
    .menu-exit {
      display: flex;
      height: 32px;
      width: 32px;
      margin: 16px;
      margin-top: 0px;
      cursor: pointer;
      margin-right: 20px;
      transition: opacity 0.2s ease-out;
    }
  }
  & .menu-list {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    padding-top: 40px;
    & li {
      ${fontStyles.menuitem};
      text-transform: uppercase;
      margin-bottom: 40px;
      color: rgba(255, 255, 255, 0.8);
      cursor: pointer;
      transition: color 0.3s ease-out;
    }
    & li:hover {
      color: white;
      transition: color 0.3s ease-out;
    }
    & li.active {
      ${fontStyles.footerHeading};
      position: relative;
      font-weight: bold;
      color: white;
    }
    & li:first-child {
      margin-top: 0px;
    }
    & li:last-child {
      margin-bottom: 60px;
    }
    & li.active::before {
      position: absolute;
      display: block;
      content: '';
      height: 1.2em;
      width: 5px;
      margin-left: -30px;
      background: white;
    }
    @media (max-width: 575px) {
      width: 70%;
      margin-top: 15px;
      margin-left: 25%;
    }
  }
  & .menu-container {
    display: flex;
    height: 10000px;
    overflow-y: scroll;
  }
  & .menu-content {
    display: flex;
    flex-direction: column;
  }

  & .nav-wrapper {
    display: flex;
    flex-direction: column;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    background-color: ${colors.black};
  }
  & .languages {
    margin-right: 30px;
    margin-bottom: 20px;
    margin-left: auto;
    width: 80px;
    display: flex;
    justify-content: space-around;
  }
  & .instance {
    display: flex;
    width: 70%;
    margin-left: 15%;
    @media (max-width: 575px) {
      margin-top: 5px;
      width: 60%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  & .instance:first-child {
    margin-top: 0px;
  }
  & .instances {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
    min-height: 300px;
    width: 100%;
  }
  & .footer {
    background-color: red;
  }
  & .footer-items {
    margin-left: 60px;
    margin-right: 60px;
    margin-top: 50px;
    display: none;
    @media (max-width: ${sizes.breakpointsMax.xl}) {
      display: block;
    }
  }
`

const Header = ({ intl }: IntlFormatters) => {
  const [isOpen, setIsOpen] = useState(false)

  // WHAT IS THIS???????
  useEffect(() => {
    const body = document.body
    if (isOpen && body) {
      /*<html>*/
      // document.documentElement.style.position = 'fixed'
      // document.documentElement.style.overflow = 'hidden'
      /*<body>*/
      // document.body.style.position = 'fixed'
      // document.body.style.overflow = 'hidden'
      /*#__next*/
      body.style.width = '100vw'
      body.style.height = '100vh'
      body.style.overflow = 'hidden'
    } else if (body) {
      document.documentElement.style.position = 'relative'
      /*<body>*/
      document.body.style.position = 'relative'
      document.body.style.overflow = 'auto'
      /*#__next*/
      body.style.width = 'auto'
      body.style.height = 'auto'
      body.style.overflow = 'auto'
    }
  }, [isOpen])

  return (
    <>
      <Wrapper $isOpen={isOpen}>
        <div className="logo-wrapper">
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <div className="burger-menu" onClick={() => setIsOpen(true)}>
          <Menu />
        </div>
        <div className={`menu`}>
          <div className="menu-header">
            <div onClick={() => setIsOpen(false)} className="menu-exit">
              <Close />
            </div>
          </div>
          <div className="menu-content">
            <div className="menu-top-box">
              <ul className="menu-list">
                <Link to="/">
                  <li className="active">{intl.formatMessage({ id: 'header.about' })}</li>
                </Link>
                <Link to="/for-user">
                  <li>{intl.formatMessage({ id: 'header.users' })}</li>
                </Link>
                <Link to="/for-company">
                  <li>{intl.formatMessage({ id: 'header.company' })}</li>
                </Link>
                <Link to="/contact">
                  <li>{intl.formatMessage({ id: 'header.contact' })}</li>
                </Link>
              </ul>
              <div className="languages">
                <a href="https://labrulez.com">
                  <EnFlag />
                </a>
                <a href="https://labrulez.cz">
                  <CzFlag />
                </a>
              </div>
            </div>
            <div className="bottom">
              <div className="footer-items">
                <Info intl={intl} />
                <SocialMedia intl={intl} />
              </div>
              <div className="instances">
                <a
                  href={intl.formatMessage({ id: 'link.gcms' })}
                  className="instance"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Gcms />
                </a>
                <a
                  href={intl.formatMessage({ id: 'link.lcms' })}
                  className="instance"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Lcms />
                </a>
                <a
                  href={intl.formatMessage({ id: 'link.icpms' })}
                  className="instance"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icpms />
                </a>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  )
}

export default injectIntl(Header)
