import styled from 'styled-components'
import { fontStyles, colors } from '../../../styles/variables'
export const Wrapper = styled.div`
  padding: 10px;
`
export const H1 = styled.h1`
  ${fontStyles.footerHeading}
  color: white;
`
export const Line = styled.div`
  height: 4px;
  margin-top: 10px;
  background-color: ${colors.light};
`
