import React from 'react'
import Helmet from 'react-helmet'
// import { favicon } from './images/favicon'

export default function Head() {
  // const icon = favicon()

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>LabRulez</title>
      {/* <link rel="icon" type="image/png" sizes="16x16" href={icon} /> */}
      {/* React Helmet for some reason does not like the favicon import */}
    </Helmet>
  )
}
