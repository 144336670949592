import React from 'react'
import styled from 'styled-components'
import { Wrapper, H1, Line } from './styles'
import Twitter from '../../../images/Twitter_filled1.svg'
import Facebook from '../../../images/Facebook_filled1.svg'
import Linkedin from '../../../images/Linkedin_filled1.svg'
import { IntlFormatters } from 'gatsby-plugin-intl'
import { GCMS_TWITTER_CS, GCMS_FACEBOOK_CS, GCMS_LINKEDIN_CS } from '../../../constants'
import { fontStyles } from '../../../styles/variables'

const SocialMediaWrapper = styled.div`
  & .links {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px;
    padding-left: 0px;
    padding-right: 0px;
    a {
      margin: auto;
    }
  }
`
const Copyright = styled.p`
  ${fontStyles.copyright};
`
const SocialMedia = ({ intl }: IntlFormatters) => {
  return (
    <Wrapper>
      <SocialMediaWrapper>
        <H1>{intl.formatMessage({ id: 'footer.heading2' })}</H1>
        <Line />
        <div className="links">
          <a href={GCMS_TWITTER_CS} target="_blank" rel="noopener noreferrer" className="link">
            <Twitter />
          </a>
          <a href={GCMS_FACEBOOK_CS} target="_blank" rel="noopener noreferrer" className="link">
            <Facebook />
          </a>
          <a href={GCMS_LINKEDIN_CS} target="_blank" rel="noopener noreferrer" className="link">
            <Linkedin />
          </a>
        </div>
      </SocialMediaWrapper>
      <Copyright>{intl.formatMessage({ id: 'footer.copyright' })}</Copyright>
    </Wrapper>
  )
}
export default SocialMedia
