import React, { ReactNode } from 'react'
import Global from '../../styles/global'
import Reset from '../../styles/reset'
import Head from '../seo'
import { GoogleAnalytics } from '../google-analytics'
import Header from '../header'

interface IProps {
  children: ReactNode
}

const Layout = (props: IProps) => {
  const { children } = props
  return (
    <>
      <Header />
      <Head />
      <Reset />
      <Global />
      <GoogleAnalytics />
      <main style={{ overflow: 'hidden' }}>{children}</main>\
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700;800&family=Open+Sans&family=Quicksand&display=swap"
        rel="stylesheet"
      ></link>
    </>
  )
}

export default Layout
