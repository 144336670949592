import { createGlobalStyle, css } from 'styled-components'
import { colors } from './variables'

const style = css`
  html {
    font-family: 'Montserrat', sans-serif;
    color: ${colors.black};
  }
`

const Global = createGlobalStyle`
  ${style}
`

export default Global
