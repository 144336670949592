import React from 'react'
import styled from 'styled-components'
import { Wrapper, H1, Line } from './styles'
import { fontStyles } from '../../../styles/variables'
import { IntlFormatters } from 'gatsby-plugin-intl'

const InfoWrapper = styled.div`
  color: white;
  & .footerListLinks {
    padding-top: 20px;
    ${fontStyles.footerSubHeading}
  }
  & .copyright {
    margin-top: 20px;
    font-style: italic;
  }
`
const Info = ({ intl }: IntlFormatters) => {
  return (
    <Wrapper>
      <InfoWrapper>
        <H1>{intl.formatMessage({ id: 'footer.heading1' })}</H1>
        <Line />
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${intl.formatMessage({ id: 'link.gcms' })}/static/terms.pdf`}
        >
          <div className="footerListLinks">{intl.formatMessage({ id: 'footer.text1' })}</div>
        </a>
        <a href="/contact">
          <div className="footerListLinks">{intl.formatMessage({ id: 'footer.text3' })}</div>
        </a>
      </InfoWrapper>
    </Wrapper>
  )
}
export default Info
